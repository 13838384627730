import React from "react";

const LoadingScreen = () => {
  return <div >
       loading

      </div>
};

export default LoadingScreen;
