import React, { useState ,useEffect} from 'react'
import './Orders.scss'
import MaterialTable from 'material-table'
import {useDispatch ,useSelector} from 'react-redux'
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import { Link } from 'react-router-dom'
import { getAllOrders, getOrderById } from '../../actions/order.action'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import { generatePublicUrl } from '../../urlConfig'
import { Avatar } from '@mui/material'


const style = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

const Orders = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllOrders())

  }, [])


  const Data = useSelector((state)=>state?.order?.orders)
  const [data, setData] = useState(Data)

  

/*  [
    {  title: t("image"), maxWidth:"60px" ,field: "images",render:(row)=><Avatar src={`${backend_url}${row.images}`}  /> },
  {  title: t("deal"), field:"service",  },
  {  title: t("status"), field: "status", },
  {  title: t("totalPrice"), field:"totalPrice", },
  {  title: t("paidAt"), field:"paidAt", render: (row)=> moment(row.paidAt).format("YYYY MM DD hh:mm") },
  {  title: t("deliveryTerm"), field:"deliveryTerm", },
  {  title: t("WarrantPeriod"), field:"WarrantPeriod", },
  {  title: t("actions"), field:"_id", render:(row)=> <div className='cellAction'> <Link
  <Link onClick={()=>{
      dispatch(getOrderById(row?._id))
    }} to={`view/${row?._id}`}
  
  className="className" >{t("view")}</Link> </div>},
   
];*/
const row = [];

Data && Data.forEach((item) => {
  row.push({
      _id: item._id,
      images:item?.service?.images[0],
      name: item?.service?.name,
      total:  item?.totalPrice+ "DZ ",
      status: item?.status,
      service:item?.service?.name,
      paidAt:item.paidAt,
      deliveryTerm:item?.service?.deliveryTerm,
      WarrantPeriod:item?.service?.WarrantPeriod
      ,totalPrice:item?.totalPrice
    });
});
  const GroupsColumns =  [
    {  title: t("image"), maxWidth:"60px" ,field: "images",render:(row)=><Avatar src={generatePublicUrl(row.images)}  /> },
  {  title: t("deal"), field:"service",  },
  {  title: t("status"), field: "status", },
  {  title: t("totalPrice"), field:"totalPrice", },
  {  title: t("paidAt"), field:"paidAt", render: (row)=> moment(row.paidAt).format("YYYY MM DD hh:mm") },
  {  title: t("deliveryTerm"), field:"deliveryTerm", },
  {  title: t("WarrantPeriod"), field:"WarrantPeriod", },
  {  title: t("actions"), field:"_id", render:(row)=> <div className='cellAction'> 
       <Link onClick={()=>{
      dispatch(getOrderById(row?._id))
    }} to={`view/${row?._id}`}
  
  className="className" >{t("view")}</Link> </div>},
   
];

    




  return (
    <div className="list">
    <Sidebar/>
    <div className="listContainer">
      <Navbar/>
      <div className='datatable'>
                    <MaterialTable columns={GroupsColumns} data={row} title="orders"
                        options={{exportButton:true,actionsColumnIndex:-1,addRowPosition:"first"}}
                    />
            </div>

    </div>
  </div>

  )
}

export default Orders